.navbarContainer {
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100vw;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid var(--color-lightGrayishBlue);
    background-color: var(--color-white);
}
.navbarContent {
    position: relative;
    max-width: 1920px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.navbarSides {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.navbarLeftSide,
.navbarRightSide {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.navItemsContainer {
    height: 100%;
    list-style: none;
    display: flex;
    column-gap: 20px;
}
.logo {
    margin-right: 50px;
}
.logo > img { 
    object-fit: fill;
    width: 100%;
    height: 100%;
}
.navbarItem {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid transparent;
}
.navbarItemActive,
.navbarItemHovered {
    transition: 0.3s all ease-in-out;
    border-bottom: 5px solid var(--color-orange);
}
.itemLabel{
    color: var(--color-darkGrayishBlue);
    cursor: pointer;
    text-decoration: none;
}
.itemLabel:hover {
    color: var(--color-black);
}
/* NavbarRightSide */
.cartIcon, .profile {
    cursor: pointer;
}
.cartIcon > img,
.profile > img{
    object-fit: fill;
    width: 100%;
    height: 100%;
}
.notification {
    position: absolute;
    z-index: 1;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: var(--color-white);
    border-radius: 40%;
    -webkit-animation: notification 0.1s ease-in both;
	animation: notification 0.1s ease-in both;
    background-color: var(--color-orange);
}
.cartIcon {
    position: relative;
    margin-right: 30px;
}
.cartIcon > img:hover {
    transition: 0.3s all ease-in;
    filter: brightness(0) saturate(100%);
}
.profile {
    border-radius: 100%;
    border: 2px solid transparent;
}
.profile:hover {
    transition: 0.3s all ease-in-out;
    border: 2px solid var(--color-orange);
}
.overlay {
    display: none;
}
.cartOverlay {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
}
.menu, .cross {
    display: none;
}
.cartProductAdded > img{
    transition: 0.3s all ease-in;
    filter: brightness(0) saturate(100%);
}
@media screen and (max-width: 768px) {
    .navbarContainer {
        height: 85px;
        column-gap: 20px;
    }
    .navbarItemActive,
    .navbarItemHovered {
        border-bottom: unset;
    }
    /* Padding del Menu y la barra*/
    .navbarContainer, .navItemsContainer {
        padding-inline: 30px;
    }
    .menuBar {
        width: 100%;
        height: 85px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .menu, .cross {
        display: flex;
    }
    .menu > img, .cross > img {
        object-fit: fill;
        width: 100%;
        height: 100%;
    }
    /* .navbarContent {
        
    } */
    .navbarSides {
        justify-content: space-between;
    }
    .navItemsContainer {
        position: fixed;
        z-index: 22;
        top: 0;
        left: 0;
        width: 70%;
        height: 100vh;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 20px;
        background-color: var(--color-white);
    }
    .navbarItem {
        border-bottom: unset;
    }
    .navbarItem {
        height: auto;
    }
    .itemLabel {
        font-weight: 700;
        color: var(--color-black);
    }
    .overlay {
        display: flex;
        position: fixed;
        z-index: 21;
        top: 0;
        left: 0;
        opacity: 75%;
        width: 100vw;
        height: 100vh;
        background-color: var(--color-black);
    }
    /* === Animations === */
    .menuOpen {
        /* display: flex; */
        -webkit-animation: slide-in-left 0.5s ease-in both;
	    animation: slide-in-left 0.5s ease-in both;
    }
    .menuClose {
        -webkit-animation: slide-in-right 0.5s ease-in both;
	    animation: slide-in-right 0.5s ease-in both;
    }
    .menuOpenBg {
        -webkit-animation: fade-in 0.5s ease-in both;
	    animation: fade-in 0.5s ease-in both;
    }
    .menuCloseBg {
        -webkit-animation: fade-out 0.5s ease-in both;
	    animation: fade-out 0.5s ease-in both;
    }
}

@media screen and (max-width: 420px) {
    .navbarContent {
        justify-content: center;
    }
    .navbarContainer, .menuBar {
        height: 70px;
    }
    /* Padding del Menu y la barra*/
    .navbarContainer, .navItemsContainer {
        padding-inline: 20px;
    }
    .logo {
        margin-right: unset;
    }
    .cartIcon {
        margin-right: 20px;
    }
}
@media screen and (max-width: 375px) {
    .logo {
        margin-right: unset;
    }
    .cartIcon {
        margin-right: 10px;
    }
}

@-webkit-keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
  }
  }
  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  
  @-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
  }
  @keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-1000px);
              transform: translateX(-1000px);
      opacity: 0;
    }
  }
  @-webkit-keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 75%; }
  }
  @keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 75%; }
  }
  
  @-webkit-keyframes fade-out {
    0% { opacity: 75%; }
    100% { opacity: 0; }
  }
  @keyframes fade-out {
    0% { opacity: 75%; }
    100% { opacity: 0; }
  }
  
  @-webkit-keyframes notification {
    0% { opacity: 0; }
    100% { opacity: 100%; }
  }
  @keyframes notification {
    0% { opacity: 0; }
    100% { opacity: 100%; }
  }
  