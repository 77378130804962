.sliderButtons {
    position: absolute;
    z-index: 1;
    width: 115%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding-inline: 15px;
}
.button {
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    background-color: var(--color-white);
}
.button > img {
    object-fit: fill;
    /* width: 15px;
    height: fit-content;    */
    width: fit-content;
    height: 20px;
}
.btnRight {
    transform: rotate(180deg);
}
@media screen and (max-width: 768px) {
    .sliderButtons {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        padding-inline: 15px;
    }
    .button {
        cursor: none;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: var(--color-white);
    }
    .button > img {
        object-fit: fill;
        /* width: 15px;
        height: fit-content;    */
        width: fit-content;
        height: 20px;
    }
    .btnRight {
        transform: rotate(180deg);
    }
}
@media screen and (max-width: 420px) {
    .sliderButtons {}
}