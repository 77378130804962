.layout {
    padding-top: 100px;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: 50px;
    /* padding-inline: 50px; */
}

@media screen and (max-width: 768px) {
    .layout {
        padding-top: 85px;
        flex-direction: column;
        column-gap: unset;
        row-gap: 10px;
    }
}

@media screen and (max-width: 420px) {
    .layout {
        padding-top: 70px;
        justify-content: flex-start;
    }
}