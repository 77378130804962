.buttonContainer {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    padding-inline: 15px;
    border-radius: 10px;
    font-weight: 700;
    column-gap: 10px;
    background-color: var(--color-orange);
}
.icon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7486%) hue-rotate(265deg) brightness(104%) contrast(103%);
}
.icon > img {
    object-fit: fill;
    width: 100%;
    height: 100%;
}
.title {
    font-weight: 700;
    color: var(--color-white);
}

@media screen and (max-width: 768px) {
    .buttonContainer {
        cursor: none;
    }
}